// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-index-js": () => import("./../../../src/pages/index/Index.js" /* webpackChunkName: "component---src-pages-index-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-sections-bio-bio-js": () => import("./../../../src/pages/index/_sections/bio/Bio.js" /* webpackChunkName: "component---src-pages-index-sections-bio-bio-js" */),
  "component---src-pages-index-sections-bio-helpers-parse-followers-count-js": () => import("./../../../src/pages/index/_sections/bio/helpers/parseFollowersCount.js" /* webpackChunkName: "component---src-pages-index-sections-bio-helpers-parse-followers-count-js" */),
  "component---src-pages-index-sections-bio-helpers-parse-views-count-js": () => import("./../../../src/pages/index/_sections/bio/helpers/parseViewsCount.js" /* webpackChunkName: "component---src-pages-index-sections-bio-helpers-parse-views-count-js" */),
  "component---src-pages-index-sections-hero-hero-js": () => import("./../../../src/pages/index/_sections/hero/Hero.js" /* webpackChunkName: "component---src-pages-index-sections-hero-hero-js" */),
  "component---src-pages-index-sections-merch-merch-js": () => import("./../../../src/pages/index/_sections/merch/Merch.js" /* webpackChunkName: "component---src-pages-index-sections-merch-merch-js" */),
  "component---src-pages-index-sections-socials-socials-js": () => import("./../../../src/pages/index/_sections/socials/Socials.js" /* webpackChunkName: "component---src-pages-index-sections-socials-socials-js" */),
  "component---src-pages-not-found-not-found-js": () => import("./../../../src/pages/not-found/NotFound.js" /* webpackChunkName: "component---src-pages-not-found-not-found-js" */)
}

